import React, { useEffect, useState } from "react";
import {Segment, Divider, Embed} from 'semantic-ui-react';
import { render } from "react-dom";
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Loader from '../FullScreenLoader'
import clientstaticlogo from '../../assets/revlocal.svg';
import Container from '../../components/Container';
import Sidebar from '../../components/Sidebar';
import { withAuth, withFirestore } from '../../config/firebase';
import Candidates from "../Candidates";
import JobTable from '../JobTable';
import WordTable from '../WordTable'
import WordCreation from '../WordCreation'
import JobCreation from '../JobCreation'
import Guide from '../Guide'
import Example from "../Guide/index";
import EmbedExampleVimeo from '../Resources/index'
import Video2 from '../Videos/index'
import interviewGuide from '../../assets/interviewGuide.pdf'
import previewImg from '../../assets/guidepreview.jpg'
import './index.scss';

  
const Admin = ({transitionDelay, ...props}) => {

  const [currentUserData, setCurrentUserData] = useState(null)
  const [background, setBackground] = useState()

  const routes = [
    {
      path: `/admin/jobs`,
      component: <>
      {/* currentUserData={currentUserData} */}
        <div>
        <JobTable setBackground={setBackground}/>
        </div>
      </>
    },
    {
      path: `/admin/words`,
      component: <>
        <div>
          <WordTable></WordTable>
        </div>
      </>
    },
    {
      path: `/admin/home`,
      component: <>
        <div>
        <Segment>
          <h3>Resources</h3>
        </Segment>
        {/*<Guide/>*/}
        <Segment>
        <h4>Job Advisor Face-to-Face Instructional Video</h4>
        <EmbedExampleVimeo />
        </Segment>
        <Divider style={{paddingTop: "50px", paddingBottom: "50px"}}></Divider>
        <Segment>
        <h4>Job Advisor Virtual Instructional Video</h4>
        <Video2 />
        </Segment>
        <Divider style={{paddingTop: "50px", paddingBottom: "50px"}}></Divider>
        <Segment inverted>
        <h3>Interview Guide</h3>
        {/*<a href={interviewGuide} target="_blank">Click Here</a>*/}
          <Embed
            icon='arrow circle down'
            //placeholder={previewImg}
            url={interviewGuide}
          />
        </Segment>
        <Divider style={{ paddingTop: "50px"}}></Divider>
        </div>
      </>
    },
    {
      path: `/admin/candidates`,
      component: <>
        <Segment>
          <h3>Candidates</h3>
      </Segment>
        <Candidates setBackground={setBackground} currentUserData={currentUserData}/>
      
      </>
    },
    {
      path: `/admin/new-word`,
      component: <>
        <div>
          <WordCreation setBackground={setBackground}></WordCreation>
        </div>
      </>
    },
    {
      path: `/admin/new-job`,
      component: <>
        <div>
          <JobCreation setBackground={setBackground}></JobCreation>
        </div>
      </>
    },
    {
      path: `/admin/edit-job`,
      component: <>
        <div>
          <JobCreation setBackground={setBackground} edit={true}></JobCreation>
        </div>
      </>
    },
    {
      path: `/admin/new-word`,
      component: <>
        <div>
          <h1>Create New Strength</h1>
          <WordCreation></WordCreation>
        </div>
      </>
    },
    {
      path: `/admin/new-job`,
      component: <>
        <div>
          <h1>Create New Job</h1>
          <JobCreation></JobCreation>
        </div>
      </>
    },
  ];
  
  useEffect(() => {

    let userSubscriber;
    const subdomainRef = props.firebase.firestore()
    .collection('clients').doc('revlocal')//.doc(`${props.client.subdomain}`)

    userSubscriber = subdomainRef
    .collection('admins').doc(`${props.currentUser.uid}`)
    .onSnapshot((doc) => {
      if (doc.exists) {
        setCurrentUserData({
          role: 'admin',
          ...doc.data()
        })
      }
    })

    return () => {
      if (userSubscriber) {
        userSubscriber()
      }
    };
  }, [props.firebase, props.currentUser]);

  if (!currentUserData) {
    return <Loader style={{height: '100vh'}}/>
  }

  return <>
    <header className="App-header">
    </header>
    <div className="App-main" style={{background: `url(${background})` }}>
      <Container style={{flexDirection: "row"}}>
        <Sidebar backgroundColor={"#1C1D1E"} width={"20%"} minWidth={"200px"} logo={clientstaticlogo}/>

        <div style={{flex: 1}}>
          <TransitionGroup data-transition-group >
          
            <CSSTransition
              key={props.location.pathname.substring(1).split('/')[1]}
              timeout={transitionDelay}
              classNames="Animate-Flex"
              unmountOnExit
              onExit={() => {
              }}
              onEntered={()=> {
              }}
            >

              <Switch location={props.location}>
                {
                  routes.map((item, i) => {
                    return (
                      <Route path={item.path} key={i} children={<div className="Animate-Flex">{item.component}</div>}/>
                    )
                  })
                }
                
                <Route>
                  <Redirect to="/pagenotfound" />
                </Route>
              
              </Switch>
              
            </CSSTransition>
          </TransitionGroup>
        </div>

      </Container>
    </div>
    <div className="App-footer">
    </div>
  </>

}

export default withFirestore(withRouter(withAuth(Admin)));

