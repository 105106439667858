import React from 'react';
//import { propTypes } from 'react-bootstrap/esm/Image';
//import Navbar from 'react-bootstrap/Navbar';
import landidLogo from '../../assets/landidlogo2.png'
import './css.css';
import { Link } from 'react-router-dom'
import { Button } from '../../components/Semantic'
import { withFirestore } from '../../config/firebase';

const Sidebar = ({...props}) => {

    const {backgroundColor, width, minWidth, logo} = props;
    
    const flex = {flex: 1};

    return (
        <div className="sidebar" style={{...props.style,
            backgroundColor: backgroundColor,
            width: width,
            minWidth: minWidth,
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            position: 'relative',
            color: 'white',
            padding: '10px',
            paddingTop: '30px',
            paddingBottom: '30px',
            minHeight: '550px',
            boxShadow: 'inset -4px 0px 5px rgba(0, 0, 0, 0.05)'
        }}>
            <div style={{...flex}}>
                <img src={logo} alt="Client Logo" width="80%"/>
            </div>
            
            <div style={{...flex}}>
                <h4><Link style={{ color: 'inherit' }} to={'/admin/home'}><i className="info circle icon"></i>&nbsp;Resources</Link></h4>
            </div>
            <div style={{...flex}}>
                <h4><Link style={{ color: 'inherit' }} to={'/admin/jobs'}><i className="address card icon"></i>&nbsp;Jobs</Link></h4>
            </div>
            <div style={{...flex}}>
                <h4><Link style={{ color: 'inherit' }} to={'/admin/words'}><i className="language icon"></i>&nbsp;Strengths</Link></h4>
            </div>
            <div style={{...flex}}>
                <h4><Link style={{ color: 'inherit' }} to={'/admin/candidates'}><i className="users icon"></i>&nbsp;Candidates</Link></h4>
            </div>

            <div style={{...flex}}>
                <h4><Button custom="true" style={{ color: 'black' }} to={'/admin/other'} onClick={()=>{props.firebase.signOut()}}><i className="logout card icon"></i>&nbsp;Logout</Button></h4>
            </div>
            <div style={{...flex}}>
                
            </div>
            
            <div style={{flex: 0.5, flexGrow: 0, flexShrink: 0}}>
                <hr/>
                <a target="_blank" rel="noreferrer" href="https://www.landid.com">
                    <img src={landidLogo} alt="Landid Logo" width="40%" style={{borderRadius: '8px', cursor: 'pointer'}}/>
                </a>
            </div>
        </div>
    )
}

export default withFirestore(Sidebar);
