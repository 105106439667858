import React, { createContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Loader from '../../views/FullScreenLoader';
import firebase from './firebase';
import { withFirestore } from "./fireContext";


export const AuthContext = createContext();

const AuthConsumer = AuthContext.Consumer;

export const AuthProvider = withFirestore(withRouter(({ children, ...props }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {

    // Listen for currentuser
    const userSubscriber = firebase.checkUserAuth((user) => {
      setCurrentUser(user)
      setPending(false)
    });
    
    return () => {
      userSubscriber();
    }
  }, []);

  // Show loader when pending
  if (pending) {
    return <Loader/>
  }

  // Otherwise continue with current user data
  return (
    <AuthContext.Provider value={{currentUser}}>
      {children}
    </AuthContext.Provider>
  );
})); 

// TODO: Clean this up
export const withAuth = Component => props => (
  <AuthConsumer>
    {context => <Component {...props} 
      currentUser={context ? context.currentUser : null} 
      />
    }
  </AuthConsumer>
);
