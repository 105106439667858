import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import Firebase, { FirebaseProvider, AuthProvider, ClientProvider } from './config/firebase';

import 'semantic-ui-css/semantic.min.css';
import './index.css';


// Filter warning from semantic ui
;(() => {
  const oldLogError = console.error
  console.error = function(...args) {
    if (typeof args[0] !== 'string' || args[0].indexOf('is deprecated in StrictMode') === -1) {
      oldLogError.apply(console, args)
    }
  }
})()

// Suppress console logs if not in dev mode
if (process.env.NODE_ENV === "production") {
  /*console.log = () => {};*/
}
    
{/*Set variables and listen to changes from database*/}
ReactDOM.render(
  <React.StrictMode>
    <FirebaseProvider value={Firebase}>
      {/* Offer subdomain information */}
        {/* Offer Auth information */}
        <Router>
          
            <AuthProvider>
              
                <App />
                
            </AuthProvider>
          
        </Router>
    </FirebaseProvider>
  </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
