import React, { Component, useState, useEffect } from 'react'
import { Button, Grid, Header, Label, Segment, Portal, Modal, List } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const Word2 = ({ wordData, questionData, backgroundColor, textColor, style, questionsArray,  ...rest}) => {

    const[open, setOpen] = useState(false);
    
  return (
    <Modal
        size='small'
        open={open}
        closeOnDimmerClick={true}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<a style={{cursor: 'pointer'}}>{wordData}</a>}
        >
        <Modal.Header>
            Questions
        </Modal.Header>
        <Modal.Content>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                    <List>
                    {
                        questionsArray.map((question, i) => {
                            return(
                            <List.Item key={i}>
                                {question}
                            </List.Item>
                            )
                        })
                    }
                    </List>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Modal.Content>
        <Modal.Actions>
            <Button  onClick={() => setOpen(false)}>Close</Button>
        </Modal.Actions>

    </Modal>
  );
}

export default Word2


