import React from 'react'
import { Embed } from 'semantic-ui-react'
import thumbnail from '../../assets/wordcreation.jpg'

const Video2 = () => (
  <Embed
    id='509808422'
    placeholder={thumbnail}
    source='vimeo'
  />
)

export default Video2