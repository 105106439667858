import React from "react";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({...props}) => {
    const {condition, redirectTo, staticContext, exact=false, children, ...rest} = props;

    return (
      <Route exact={exact} {...rest}>
          {
              condition ? 
                children
             : (
              <Redirect to={{
                  pathname: redirectTo,
                  state: { from: props.location }
              }}/>
            )
          }
      </Route>
    );
  }

export default PrivateRoute;