import Firebase from './firebase'
import { FirebaseProvider, withFirestore } from './fireContext'
import { AuthProvider, withAuth, AuthContext } from './authContext'
import PrivateRoute from './privateRoute'

export { 
    FirebaseProvider, 
    withFirestore, 
    
    AuthProvider, 
    withAuth, 
    AuthContext, 

    
    PrivateRoute
    
};

export default Firebase;