import userEvent from '@testing-library/user-event';
import React, { useEffect, useState } from 'react';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import { Form, Input, Grid, Message, Segment } from 'semantic-ui-react';
import { Button } from '../../components/Semantic';
import { withFirestore } from '../../config/firebase';
import CandidateProfile from '../CandidateProfile';
import Loader from '../FullScreenLoader'
import './css.scss';
import candidates from "../../assets/candidates.jpg"
import emailjs from 'emailjs-com';
import { object } from 'yup';

const Candidates = ({...props}) => {

    const [candidateList, setCandidateList] = useState([]);
    const [searchParam, setSearchParam] = useState('');

    const [email, setEmail] = useState(''); // Set email
    const [career, setCareer] = useState(''); // Set career id 
    const [options, setOptions] = useState(null); // Set career id list

    const [status, setStatus] = useState(false)

     const _handleSubmit = () => {
        setStatus(true)

        if (career==='' || career===null || email==='' || email===null) {
            setStatus({header: 'Error', content: 'The form is incomplete'})
            return;
        }

        const functions = props.firebase.functions;

        //functions.useFunctionsEmulator('http://localhost:5001')

        const linkFunction = functions.httpsCallable('getEmailLink');
        
        
        linkFunction({
            email: email,
            role: career,
            client: 'revlocal'
        })
        .then(data => {

            //development
            
            var info = {
                job_title: options.filter(e => {return career==e.value})[0].text,	
                company_name: "revlocal",	
                link: data.data.link,
                to_email: email
            }

            


            emailjs.send('service_5g9pkta', 'template_384jo7o', info, 'user_LcnqefyVpJqjfmNozM54m')
             .then((response) => {
                 setEmail('')
                 setStatus({header: 'Success', content: 'The email request was sent'})
             }, (err) => {
                 setStatus({header: 'Error', content: 'The email could not be sent'})
             });

             
        }).catch(err => {
            setStatus({header: 'Error', content: 'The email could not be sent'})
        })


        


      }

    useEffect(() => {
        if (searchParam.length > 3) {
            props.firebase.firestore().collection(`clients/revlocal/candidates`)//${props.client.subdomain}
            .where('email', '>=', searchParam)
            .where('email', '<=', searchParam+'\uf8ff')
            .limit(10)
            .get().then((querySnapshot) => {
                const sortedCollection = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));
                
                // If a is newer than b, then return 1 else -1
                sortedCollection.sort((a, b) => (a.created > b.created) ? 1 : -1);

                setCandidateList(sortedCollection);
            }).catch((err) => {

            })
        } else if (candidateList.length > 0) {
            setCandidateList([])
        }

        return () => {

        }
        
    }, [props.firebase, searchParam, candidateList.length])


    useEffect(() => {
        const subscriber = props.firebase.firestore()
        .collection('clients').doc('revlocal')//.doc(`${props.client.subdomain}`)
        .collection('careers')
        .onSnapshot((col)=> {
          const list = [];
          col.forEach(doc => {
            const element = doc.data();
            list.push({
              key: doc.id,
              text: element.title,
              value: doc.id,
            })
          })
          list.sort((a, b) => a.text.localeCompare(b.text))
          setOptions(list)
        })
  
        props.setBackground(candidates)
          //getData()
  
        return () => {
          if (subscriber) {
            subscriber()
          }
  
        }

        
        


      }, [props.firebase])

    if (!options) {
        return <Loader />
    }


    return (
        <div>
            <Switch>
                <Route exact path={`${props.match.url}`}>
                    <div style={{marginTop: 30}}>
                        <Grid>
                            <Grid.Row centered>
                                <Form onSubmit={_handleSubmit} error={status.header==="Error"} success={status.header==="Success"}>
                                    <Form.Group>
                                        <Form.Input placeholder="Enter Email..." onChange={(e)=> {setEmail(e.target.value)}} value={email}/>
                                        <Form.Select placeholder="Job" options={options} onChange={(e, {value})=> {setCareer(value)}} />
                                    </Form.Group>
                                    <Message
                                        error
                                        header={status.header}
                                        content={status.content}
                                    />
                                    <Message
                                        success
                                        header={status.header}
                                        content={status.content}
                                    />
                                    <Button content='Submit' custom="true" disabled={typeof(status) === "object" ? false : status}/>
                                </Form>
                            </Grid.Row>
                        </Grid>
                    </div>
                   


                  {/*  <div style={{minHeight: '200px', display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>
                        <Input placeholder="Search Candidate Email..." onChange={(e)=> {setSearchParam(e.target.value)}}/>
                    </div>

                  */}
                  {/*
                    <div>
                        <ul className={'search-result-ul'} style={{
                            display: 'flex', 
                            listStyleType: 'none', 
                            padding: 0, 
                            flexDirection: 'column', 
                            overflowY: 'scroll', 
                            overflow: 'visible', 
                            margin: 'auto', 
                            marginTop: '10px', 
                            width: '40%', 
                            minWidth: '200px', 
                            maxWidth: '400px',
                        }}>
                            {
                                candidateList.length > 0 && candidateList.map((candidiate, i) => {
                                    return <Link className={`repeat-link`} style={{color: '#222', cursor: 'pointer', borderRadius: '4px', marginBottom: '10px', padding: 20, display: 'block'}} to={`${props.match.url}/${candidiate.id}`} key={i}>
                                        <i style={{color: 'var(--brand-maincolor)'}} className="user icon"></i>
                                        <p>{candidiate.email}</p>
                                        {/*<br /><p style={{color: 'lightgray'}}>{candidiate.id}</p>
                                    </Link>
                                })
                            }
                            {
                                candidateList.length === 0 && <h6 style={{color: 'lightgray'}}>Sorry, there's no one here</h6>
                            }
                        </ul>
                    </div
                    >*/}
                </Route>
                <Route path={`${props.match.url}/:candidateId`}>
                    <CandidateProfile/>
                </Route>
            </Switch>
        </div>
    )
}

export default withFirestore(withRouter(Candidates));
