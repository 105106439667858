import React, { useEffect } from "react";
import './index.scss';
import { Link } from "react-router-dom";
import Container from '../../components/Container';

const PageNotFound = () => {
  
  useEffect(() => {
      
    return () => {
      console.log("... Dismounting")
    };
  }, []);

  return <>
    <header className="App-header">
    </header>
    <div className="App-main">
      <Container style={{paddingRight: '10%', paddingLeft: '10%', flexDirection: 'row', height: '100vh', alignItems: 'center', minWidth: '400px'}}>
        <div>
          
          <i className="search icon" style={{
            width: '100%', 
            maxWidth: '400px', 
            minWidth: '200px',
            color: 'lightgray', 
            lineHeight: 1, 
            verticalAlign: 'middle'}}>

            </i>
        </div>
        <div style={{textAlign: 'left', paddingLeft: 10}}>
          <h1>404</h1>
          <h3>We'll help you find the job... <br/><br/>But after we find this page</h3>
          <h4>What were you looking for?</h4>
          <ul>
            <li>If you're an Admin <Link to={`/login`}>log in</Link> and head over to the <Link to={`/admin/home`}>Admin</Link> page</li>
            <li>If you're a User, ask your interviewer for next steps.</li>
          </ul>
        </div>
        
      </Container>
    </div>
    <div className="App-footer">
    </div>
  </>

}

export default PageNotFound;

