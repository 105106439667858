import React from 'react'
import { Embed } from 'semantic-ui-react'
import thumbnail from '../../assets/fillerimg.jpg'

const EmbedExampleVimeo = () => (
  <Embed
    id='518141911'
    placeholder={thumbnail}
    source='vimeo'
  />
)

export default EmbedExampleVimeo