/*import React, { Component } from 'react';
import FileViewer from 'react-file-viewer';
import guide from '../../assets/InterviewGuideTemplate.docx'

const file = guide
const type = 'docx'

class Example extends React.Component {
  render() {
    return (
      <FileViewer
        fileType={type}
        filePath={file}
        onError={this.onError}/>
    );
  }

  onError(e) {
    
  }
}
export default Example*/

import React from "react";
import ReactToPrint from "react-to-print";
import { Item, List, Form, Grid, Message, Segment, Divider} from 'semantic-ui-react'



class ComponentToPrint extends React.Component {


  constructor(props){
    super(props)
    this.initState = {
      word: '',
      jobtitle: '',
      interviewer: '',
      user: '',
      date:'',
      definition: '',
      question1: '',
      question2: '',
      question3: ''
    } 
    this.state = {...this.initState, status: false}
  }
  
  
   handleChange = (e, { word, value }) => this.setState({ [word]: value })
   handleSubmit = () => {
     const { word, definition, user, interviewer, date, question1, question2, question3 } = this.state
     console.log(this.state)
     
     if (!(word!=='' && definition!=='' && question1!=='' && question2!=='' && question3!=='')) {
       this.setState(prevState => ({
         ...prevState,
          status: {
            header: 'Error',
            content: 'The form is not formatted correctly'
          }
      }))
       return;
     }
    }

  render() {
    const { word, definition, jobtitle, interviewer, user, date, question1, question2, question3, status } = this.state

    return (
      <div>
        <Grid centered columns='equal'>
        <Segment size='massive' compact>
          <h4>Interview Guide</h4>
          <Grid.Row stretched>
            <Grid.Column>
              <Form>
                <Form.Group widths='equal'>
                <Form.Input
                    size = 'massive'
                    fluid label='Job Title'
                    placeholder='Enter Job Title'
                    word='word'
                    value={word}
                    onChange={this.handleChange}
                  />
                  </Form.Group>
                  <Form.Input
                    size = 'massive'
                    fluid label='Candidate Name'
                    placeholder='Enter Name'
                    word='user'
                    value={user}
                    onChange={this.handleChange}
                  />
                  <Form.Input
                    size = 'massive'
                    fluid label='Interview Date'
                    placeholder='MM/DD/YYYY'
                    word='date'
                    value={date}
                    onChange={this.handleChange}
                  />
                  <Form.Input
                    size = 'massive'
                    fluid label='Interviewed By'
                    placeholder='Enter Name'
                    word='interviewer'
                    value={interviewer}
                    onChange={this.handleChange}
                  />
                <Item style={{textAlign: "left"}}>
                    <Item.Content>
                        <Item.Header><strong>Breaking The Ice</strong></Item.Header>
                        <List>
                        <List.Item><strong>Step 1: </strong>Break the ice with the candidate. Make them feel comfortable.</List.Item>
                        <List.Item><strong>Step 2: </strong> Set up the structure of the interview for the candidate:</List.Item>
                        <List.Item>
                            <List.List>
                            <List.Item>1. The first part of the interview will be getting to know each other.</List.Item>
                            <List.Item>2. The middle portion of the interview will focus on interview questions.</List.Item>
                            <List.Item>3. At the end, the candidate will have an opportunity to ask questions. </List.Item>
                            </List.List>
                        </List.Item>
                        <List.Item><strong>Step 3: </strong>  Ask candidate to “tell me about yourself”. Share information about yourself as well.</List.Item>
                        </List>
                        <Item.Extra>Additional Details</Item.Extra>
                    </Item.Content>
                    </Item>
                  <Form.TextArea 
                    style={{ minHeight: 200 }}
                    label='Notes' 
                    placeholder='Breaking the Ice Notes...'
                    word='definition'
                    value={definition}
                    onChange={this.handleChange}
                  />
                <List style={{textAlign: "left"}}>
                  <List.Item >
                  <Item.Group>
                    <Item>
                    <Item.Content>
                        <Item.Header>Capability 1: </Item.Header>
                        <Item.Meta>Question</Item.Meta>
                        <Item.Description>
                        Notes:
                        <List.Item>
                            <Form.TextArea
                                label='What was the situation or task?' 
                                placeholder='Answer...'
                                    
                            />
                        </List.Item>
                        <List.Item>
                        <Form.TextArea
                                label='What actions did the interviewee take in this situation or task?' 
                                placeholder='Answer...'
                                
                            />
                        </List.Item>
                        <List.Item><Form.TextArea
                                label='What were the results of the interviewee’s actions?' 
                                placeholder='Answer...'
                            />
                        </List.Item>
                        </Item.Description>
                        <List.Item>
                        
                        <Item.Extra>
                        <List.List>
                            <List.Item>High - Answer completely matched “listen/look for’s”.</List.Item>
                            <List.Item>Medium – Answer sometimes matched “listen/look for’s”.</List.Item>
                            <List.Item>Low – Answer did not match “listen/look for’s”.</List.Item>
                        </List.List>
                            <Form.Input
                                fluid label='Rating'
                                placeholder='High, Medium, or Low...'
                            />
                        </Item.Extra>
                        </List.Item>
                    </Item.Content>
                    </Item>
                </Item.Group>
                <Divider style={{paddingTop: "50px", paddingBottom: "50px"}}></Divider>
                    </List.Item>
                    <List.Item >
                    <Item.Group>
                        <Item>
                        <Item.Content>
                            <Item.Header>Capability 2: </Item.Header>
                            <Item.Meta>Question</Item.Meta>
                            <Item.Description>
                            Notes:
                            <List.Item>
                                <Form.TextArea
                                    label='What was the situation or task?' 
                                    placeholder='Answer...'
                                        
                                />
                            </List.Item>
                            <List.Item>
                            <Form.TextArea
                                    label='What actions did the interviewee take in this situation or task?' 
                                    placeholder='Answer...'
                                    
                                />
                            </List.Item>
                            <List.Item><Form.TextArea
                                    label='What were the results of the interviewee’s actions?' 
                                    placeholder='Answer...'
                                />
                            </List.Item>
                            </Item.Description>
                            <List.Item>
                            
                            <Item.Extra>
                            <List.List>
                                <List.Item>High - Answer completely matched “listen/look for’s”.</List.Item>
                                <List.Item>Medium – Answer sometimes matched “listen/look for’s”.</List.Item>
                                <List.Item>Low – Answer did not match “listen/look for’s”.</List.Item>
                            </List.List>
                                <Form.Input
                                    fluid label='Rating'
                                    placeholder='High, Medium, or Low...'
                                />
                            </Item.Extra>
                            </List.Item>
                        </Item.Content>
                        </Item>
                    </Item.Group>
                    </List.Item>
                    <Divider style={{paddingTop: "50px", paddingBottom: "50px"}}></Divider>
                    <List.Item >
                    <Item.Group>
                        <Item>
                        <Item.Content>
                            <Item.Header>Capability 3: </Item.Header>
                            <Item.Meta>Question</Item.Meta>
                            <Item.Description>
                            Notes:
                            <List.Item>
                                <Form.TextArea
                                    label='What was the situation or task?' 
                                    placeholder='Answer...'
                                        
                                />
                            </List.Item>
                            <List.Item>
                            <Form.TextArea
                                    label='What actions did the interviewee take in this situation or task?' 
                                    placeholder='Answer...'
                                    
                                />
                            </List.Item>
                            <List.Item><Form.TextArea
                                    label='What were the results of the interviewee’s actions?' 
                                    placeholder='Answer...'
                                />
                            </List.Item>
                            </Item.Description>
                            <List.Item>
                            
                            <Item.Extra>
                            <List.List>
                                <List.Item>High - Answer completely matched “listen/look for’s”.</List.Item>
                                <List.Item>Medium – Answer sometimes matched “listen/look for’s”.</List.Item>
                                <List.Item>Low – Answer did not match “listen/look for’s”.</List.Item>
                            </List.List>
                                <Form.Input
                                    fluid label='Rating'
                                    placeholder='High, Medium, or Low...'
                                />
                            </Item.Extra>
                            </List.Item>
                        </Item.Content>
                        </Item>
                    </Item.Group>
                    </List.Item>
                    <Divider style={{paddingTop: "50px", paddingBottom: "50px"}}></Divider>
                    <List.Item >
                    <Item.Group>
                        <Item>
                        <Item.Content>
                            <Item.Header>Capability 4: </Item.Header>
                            <Item.Meta>Question</Item.Meta>
                            <Item.Description>
                            Notes:
                            <List.Item>
                                <Form.TextArea
                                    label='What was the situation or task?' 
                                    placeholder='Answer...'
                                        
                                />
                            </List.Item>
                            <List.Item>
                            <Form.TextArea
                                    label='What actions did the interviewee take in this situation or task?' 
                                    placeholder='Answer...'
                                    
                                />
                            </List.Item>
                            <List.Item><Form.TextArea
                                    label='What were the results of the interviewee’s actions?' 
                                    placeholder='Answer...'
                                />
                            </List.Item>
                            </Item.Description>
                            <List.Item>
                            
                            <Item.Extra>
                            <List.List>
                                <List.Item>High - Answer completely matched “listen/look for’s”.</List.Item>
                                <List.Item>Medium – Answer sometimes matched “listen/look for’s”.</List.Item>
                                <List.Item>Low – Answer did not match “listen/look for’s”.</List.Item>
                            </List.List>
                                <Form.Input
                                    fluid label='Rating'
                                    placeholder='High, Medium, or Low...'
                                />
                            </Item.Extra>
                            </List.Item>
                        </Item.Content>
                        </Item>
                    </Item.Group>
                    <Divider style={{paddingTop: "50px", paddingBottom: "50px"}}></Divider>
                    <Item.Group>
                        <Item>
                        <Item.Content>
                            <Item.Header>Capability 5: </Item.Header>
                            <Item.Meta>Question</Item.Meta>
                            <Item.Description>
                            Notes:
                            <List.Item>
                                <Form.TextArea
                                    label='What was the situation or task?' 
                                    placeholder='Answer...'
                                        
                                />
                            </List.Item>
                            <List.Item>
                            <Form.TextArea
                                    label='What actions did the interviewee take in this situation or task?' 
                                    placeholder='Answer...'
                                    
                                />
                            </List.Item>
                            <List.Item><Form.TextArea
                                    label='What were the results of the interviewee’s actions?' 
                                    placeholder='Answer...'
                                />
                            </List.Item>
                            </Item.Description>
                            <List.Item>
                            
                            <Item.Extra>
                            <List.List>
                                <List.Item>High - Answer completely matched “listen/look for’s”.</List.Item>
                                <List.Item>Medium – Answer sometimes matched “listen/look for’s”.</List.Item>
                                <List.Item>Low – Answer did not match “listen/look for’s”.</List.Item>
                            </List.List>
                                <Form.Input
                                    fluid label='Rating'
                                    placeholder='High, Medium, or Low...'
                                />
                            </Item.Extra>
                            </List.Item>
                        </Item.Content>
                        </Item>
                    </Item.Group>
                    <Divider style={{paddingTop: "50px", paddingBottom: "50px"}}></Divider>
                    <Item.Group>
                        <Item>
                        <Item.Content>
                            <Item.Header>Capability 6: </Item.Header>
                            <Item.Meta>Question</Item.Meta>
                            <Item.Description>
                            Notes:
                            <List.Item>
                                <Form.TextArea
                                    label='What was the situation or task?' 
                                    placeholder='Answer...'
                                        
                                />
                            </List.Item>
                            <List.Item>
                            <Form.TextArea
                                    label='What actions did the interviewee take in this situation or task?' 
                                    placeholder='Answer...'
                                    
                                />
                            </List.Item>
                            <List.Item><Form.TextArea
                                    label='What were the results of the interviewee’s actions?' 
                                    placeholder='Answer...'
                                />
                            </List.Item>
                            </Item.Description>
                            <List.Item>
                            
                            <Item.Extra>
                            <List.List>
                                <List.Item>High - Answer completely matched “listen/look for’s”.</List.Item>
                                <List.Item>Medium – Answer sometimes matched “listen/look for’s”.</List.Item>
                                <List.Item>Low – Answer did not match “listen/look for’s”.</List.Item>
                            </List.List>
                                <Form.Input
                                    fluid label='Rating'
                                    placeholder='High, Medium, or Low...'
                                />
                            </Item.Extra>
                            </List.Item>
                        </Item.Content>
                        </Item>
                    </Item.Group>
                    <Divider style={{paddingTop: "50px", paddingBottom: "50px"}}></Divider>
                    <Item.Group>
                        <Item>
                        <Item.Content>
                            <Item.Header>Capability 7: </Item.Header>
                            <Item.Meta>Question</Item.Meta>
                            <Item.Description>
                            Notes:
                            <List.Item>
                                <Form.TextArea
                                    label='What was the situation or task?' 
                                    placeholder='Answer...'
                                        
                                />
                            </List.Item>
                            <List.Item>
                            <Form.TextArea
                                    label='What actions did the interviewee take in this situation or task?' 
                                    placeholder='Answer...'
                                    
                                />
                            </List.Item>
                            <List.Item><Form.TextArea
                                    label='What were the results of the interviewee’s actions?' 
                                    placeholder='Answer...'
                                />
                            </List.Item>
                            </Item.Description>
                            <List.Item>
                            
                            <Item.Extra>
                            <List.List>
                                <List.Item>High - Answer completely matched “listen/look for’s”.</List.Item>
                                <List.Item>Medium – Answer sometimes matched “listen/look for’s”.</List.Item>
                                <List.Item>Low – Answer did not match “listen/look for’s”.</List.Item>
                            </List.List>
                                <Form.Input
                                    fluid label='Rating'
                                    placeholder='High, Medium, or Low...'
                                />
                            </Item.Extra>
                            </List.Item>
                        </Item.Content>
                        </Item>
                    </Item.Group>
                    </List.Item>
                  </List>
                <Message
                  success
                  />
                <Message
                  error
                  
                />
            </Form>
          </Grid.Column>
        </Grid.Row>
        </Segment>
      </Grid>
    </div>
    );
  }
}

class Example extends React.Component {
  render() {
    return (
      <div>
        <ComponentToPrint ref={(el) => (this.componentRef = el)} />
        <Segment style={{margin: "50px 0 0 300px", width: "100%",  justifyContent: "center", maxWidth:"50%"}}>
        <ReactToPrint
          trigger={() => <a href="#"><strong>Print This Out</strong></a>}
          content={() => this.componentRef}
        />
        </Segment>
        <Divider style={{paddingTop: "50px"}}></Divider>
      </div>
    );
  }
}

export default Example;
