import React, {  useState, useEffect } from 'react'
import { Form, Dropdown, Grid, Message, Segment, Header } from 'semantic-ui-react'
import { withFirestore } from '../../config/firebase'
import jobimage from '../../assets/jobcreation.jpg'
import { useHistory } from 'react-router-dom'

const Job = ({...props}) => {

  const [state, setState] = useState({})
  const [wordList, setWordList] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)

  const history = useHistory();

  const handleChange = (e, { title, value }) => {
    setState(prevState => {
      return { ...prevState, [title]: value }
    })
    console.log(state)
  }

  const handleSubmit = () => {
    const { title, description, words } = state
    
    const wordsArray = [];
    words.map((item, index)=> {
      wordsArray.push(props.firebase.firestore().collection("clients").doc('revlocal').collection('words').doc(item)) 
    });

    const ref = props.firebase.firestore().collection('clients').doc('revlocal').collection('careers');
    
    if (props.edit) {
      ref.doc((new URLSearchParams(window.location.search)).get('jobid')).set({
        selected: wordsArray.slice(0, 4),
        unselected: wordsArray.slice(4, 12),
        title: title,
        description: description
      }, {merge: true})
      .then(() => {
        setState({})
        setSuccessMessage(true)
      })
    } else {
      ref.add({
        selected: wordsArray.slice(0, 4),
        unselected: wordsArray.slice(4, 12),
        title: title,
        description: description
      }).then(() => {
        setState({})
        setSuccessMessage(true)
      })
    }
    
  }

  useEffect(() => {

    console.log(wordList, props.edit, (new URLSearchParams(window.location.search)).get('jobid'))
    
    if (wordList && props.edit && (new URLSearchParams(window.location.search)).get('jobid')) {
      
      props.firebase.firestore().collection('clients').doc('revlocal')
      .collection('careers').doc((new URLSearchParams(window.location.search)).get('jobid')).get()
      .then((doc) => {

        if (doc.exists) {
          const data = doc.data();

          console.log(data);

          setState({
            description: data.description,
            title: data.title,
            words: [...data.selected.map(i => i.id), ...data.unselected.map(i => i.id)]
          })

        } else {
          history.push({
            pathname: '/admin/jobs'
          })
        }
        
      })
    }

  }, [wordList])


  useEffect(() => {

    props.setBackground(jobimage)

    props.firebase.firestore().collection('clients').doc('revlocal').collection('words').get()
    .then(collectionSnapshot => {
      const list = [];
      
      collectionSnapshot.forEach(element => {
      
        list.push({
          key: element.id, 
          text: element.data().word,
          value: element.id
        });

      });

      setWordList(list);

    }).catch((err) => {
      console.log(err)
    });
    
  }, [])

  return (
    <div style={{margin: "50px 0 0 300px", width: "100%",  justifyContent: "center", maxWidth:"50%"}}>
      
         
        
      <Grid centered columns='equal'>
         
      <Segment size='massive' compact>
        <h4>{props.edit ? "Edit Job" : "Create New Job"}</h4>
      <Grid.Row stretched>
      <Grid.Column width={6}>
      <Form success={successMessage} onSubmit={handleSubmit}>
        <Form.Group widths='equal'>
          <Form.Input
            size = 'massive'
            label='Job Title'
            placeholder='Job title'
            title='title'
            value={state.title ? state.title : ''}
            onChange={handleChange}
          />
          </Form.Group>
          <Form.Group widths="equal">
          <Form.TextArea 
            label='Job Description' 
            size='massive'
            placeholder='Insert job description...'
            title='description'
            value={state.description ? state.description : ''}
            onChange={handleChange}
          />
          </Form.Group>
          <Segment placeholder>
            <Header>
            Please select the strengths that are a part of your profile first.
            Please list them in order of importance (first being most important). 
            Fill in the remaining slots with whatever strengths you would like.
            </Header>
            <Dropdown
            placeholder='Select 12 Words'
            fluid
            multiple
            selection
            search
            title="words"
            value={state.words ? state.words : []}
            onChange={handleChange}
            options={wordList ? wordList : []}
          />
          
           </Segment>
           
          <Form.Button 
            disabled={state.words ? state.words.length !== 12 : true}
            content='Submit' />
          <Message
            success
            header='Form Completed'
            content={`Successfully ${props.edit ? "edited" : "created a new"} job`}
            />
            
           {/* <Message negative success>
              <Message.Header>Word Limit Reached (12)</Message.Header>
              <p>If you want to select another word, delete another then continue</p>
            </Message>*/}
      </Form>
      </Grid.Column>
            </Grid.Row>
      </Segment>
      </Grid>
      
    </div>
  )

}

export default withFirestore(((Job)))


