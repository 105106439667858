import React, { useEffect, useState } from "react"
import { Redirect, Route, Switch, withRouter, Prompt } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import instructionPNG from '../../assets/associnstruction.png'
import instructionGif from '../../assets/instructions.gif'
import questionmark from '../../assets/questionmark.jpg'
import CardContainer from "../../components/Card"
import Container from '../../components/Container'
import DragContainer from '../../components/DragContainer'
import DragContTopFour from '../../components/DragContTopFour'
import Instructions from '../../components/Instructions'
import { Button, Progress } from '../../components/Semantic'
import { withAuth, withFirestore } from '../../config/firebase'
import ComparisonTable from '../Comparison'
import Finish from "../Finish"
import Loader from '../FullScreenLoader'
import topFourImg from '../../assets/topFour.png'
import './index.scss'
import Question from '../Question'


const User = ({transitionDelay, match, ...props}) => {

  // Used to preserve user's state over refresh
  const [stateStorage, setStateStorage] = useSessionStorage('userApplicationState', {
    strengths: null,
    miscellaneous: null,
    opportunity: null,
    start: null,
    topFour: null,
    startFour: null,
    selected: null,
    unselected: null
  })

  // Used to control forward backward buttons
  const [backButtonDisabled, setBackButtonDisabled] = useState(false);
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(false);
  const [nextBackButtonDisabled, setNextBackButtonDisabled] = useState(false);
  const [nextContinueButtonDisabled, setNextContinueButtonDisabled] = useState(false);

  // Used to house subscription of user's data
  const [currentUserData, setCurrentUserData] = useState(null);

  // Used to store relevant data about application
  const [strengthData, setStrengthData] = useState(stateStorage.strengths);
  const [miscData, setMiscData] = useState(stateStorage.miscellaneous);
  const [oppData, setOppData] = useState(stateStorage.opportunity);
  const [startData, setStartData] = useState(stateStorage.start);
  const [topFour, setTopFour] = useState(stateStorage.topFour);
  const [startFourData, setStartFourData] = useState(stateStorage.startFour);

  // Company career data
  const [selected, setSelected] = useState(null);
  const [unselected, setUnselected] = useState(null);

  // Used to denote an error
  const [error, setError] = useState(false);

  // Used to set background
  const [background, setBackground] = useState(false);
  

  // Used to transition to next page and store state
  const handleClick = (delta) => {
    const currentView = pathArray.indexOf(match.url);
    if (currentView + delta >= pathArray.length || currentView + delta < 0) return;
    setStateStorage({
      strengths: strengthData,
      miscellaneous: miscData,
      opportunity: oppData,
      start: startData,
      topFour: topFour,
      startFour: startFourData
    })
    props.history.push(pathArray[currentView + delta]);
  }
 
  /* Retrieve value from scss file for animation duration */
  const routes = [
    {
      path:`/user/association-instruction`,
      component: <>
        <Instructions pchild={<p>You will be shown pairs of images. All you need to do is pick the one that you prefer
most. This is just for fun, there is no right or wrong answer. Relax and enjoy!
        </p>}
        title={"Let’s Play a Game!"}
        subtitle={"Instructions"}
        graphic={instructionPNG}
        go={"/user/association-instruction"}
        setBackButtonDisabled={setBackButtonDisabled}
        setContinueButtonDisabled={setContinueButtonDisabled}
        setNextBackButtonDisabled={setNextBackButtonDisabled} 
        setNextContinueButtonDisabled={setNextContinueButtonDisabled}

        setBackground={setBackground}
        />
      </>
    },
    {
      path:`/user/association`,
      component: <>
        <CardContainer 
        currentUserData={currentUserData}
        didFinish={handleClick}
        setBackButtonDisabled={setBackButtonDisabled}
        setContinueButtonDisabled={setContinueButtonDisabled}
        setNextBackButtonDisabled={setNextBackButtonDisabled} 
        setNextContinueButtonDisabled={setNextContinueButtonDisabled}
        />
      </>
    },
    {
      path:`/user/sort-instruction`,
      component: <>
        <Instructions 
        currentUserData={currentUserData}
        pchild={<p>
          You will be shown a list of 12 strengths on the next screen. You can read their
              definitions by hovering your cursor over them. Drag and drop your top four strengths into the <strong>“Top
              Strengths”</strong> box. Drag and drop your bottom four into the <strong>“Top Opportunities”</strong> box. Finally, drag and drop
              the remaining four into the <strong>“Wildcard”</strong> box, but make sure to rank order them in terms of personal
              strength (top strength at the top).</p>}
        title={"Let’s Learn About Your Superpowers!"}
        subtitle={"Instructions"}
        graphic={instructionGif}
        go={"/user/sort-instruction"}
        setBackButtonDisabled={setBackButtonDisabled}
        setContinueButtonDisabled={setContinueButtonDisabled}
        setNextBackButtonDisabled={setNextBackButtonDisabled} 
        setNextContinueButtonDisabled={setNextContinueButtonDisabled}

        setBackground={setBackground}
        />
      </>
    },
    {
      path:`/user/sort`,
      component: <>
        <DragContainer 
        
        setStrengthData={setStrengthData}
        setMiscData={setMiscData}
        setOppData={setOppData}
        setStartData={setStartData}

        strengthData={strengthData}
        miscData={miscData}
        oppData={oppData}
        startData={startData}

        currentUserData={currentUserData}
        selected={selected}
        unselected={unselected}
        didFinish={handleClick} 

        setBackButtonDisabled={setBackButtonDisabled}
        setContinueButtonDisabled={setContinueButtonDisabled}
        setNextBackButtonDisabled={setNextBackButtonDisabled} 
        setNextContinueButtonDisabled={setNextContinueButtonDisabled}
        columnBackgroundColorActive={null}/>
      </>
    },
    {
      path:`/user/question-instruction`,
      component: <>
        <Instructions 
        currentUserData={currentUserData}
        pchild={<p>Now that you have rank-ordered your strengths, I will be asking you some questions
          about them. I will call out a strength by name and would like for you to click on its box.
          At that time, a question will appear on your screen. Take some time to think of a response and then
          share it.
        </p>}
        title={"Let’s Talk About You!"}
        subtitle={"Instructions"}
        graphic={questionmark}
        go={`/user/question-instruction`}
        setBackButtonDisabled={setBackButtonDisabled}
        setContinueButtonDisabled={setContinueButtonDisabled}
        setNextBackButtonDisabled={setNextBackButtonDisabled} 
        setNextContinueButtonDisabled={setNextContinueButtonDisabled}

        setBackground={setBackground}
        />
      </>
    },
    {
      path:`/user/question`,
      component: <>
      {/*currentUserData={currentUserData}*/}
        <Question
        currentUserData={currentUserData}
        strengthData={strengthData}
        miscData={miscData}
        oppData={oppData}
        selected={selected}
        unselected={unselected}
        didFinish={handleClick}
        setBackButtonDisabled={setBackButtonDisabled}
        setContinueButtonDisabled={setContinueButtonDisabled}
        setNextBackButtonDisabled={setNextBackButtonDisabled} 
        setNextContinueButtonDisabled={setNextContinueButtonDisabled}
        
        columnBackgroundColorActive={null}/>
      </>
    },
    {
      path:`/user/top4-instruction`,
      component: <>
        {/*<div><Top4Instruc/></div>*/}
        <Instructions 
        currentUserData={currentUserData}
        pchild={<p>Based on what you know about this role, what do you think are the top four strengths
          needed for success. Drag and drop the top four from the “Strengths” box to the “Top 4” box.</p>}
        title={"Let’s Talk About the Role!"}
        subtitle={"Instructions"}
        graphic={topFourImg}
        setBackButtonDisabled={setBackButtonDisabled}
        setContinueButtonDisabled={setContinueButtonDisabled}
        setNextBackButtonDisabled={setNextBackButtonDisabled} 
        setNextContinueButtonDisabled={setNextContinueButtonDisabled}
        setBackground={setBackground}
        />
      </>
    },
    {
      path:`/user/top4`,
      component: <>
        <DragContTopFour 
        selected={selected}
        unselected={unselected}
        strengthData={strengthData}
        miscData={miscData}
        oppData={oppData}
        topFour={topFour}
        setTopFour={setTopFour}
        startFourData={startFourData}
        setStartFourData={setStartFourData}
        currentUserData={currentUserData}
        setBackButtonDisabled={setBackButtonDisabled}
        setContinueButtonDisabled={setContinueButtonDisabled}
        setNextBackButtonDisabled={setNextBackButtonDisabled} 
        setNextContinueButtonDisabled={setNextContinueButtonDisabled}
        />
      </>
    },
    {
      path:`/user/comparison`,
      component: <>
      {/*currentUserData={currentUserData}*/}
        <div><h1 style={{color: "white"}}>Comparison</h1></div>
        <ComparisonTable 
        currentUserData={currentUserData}
        strengthData={strengthData}
        topFour={topFour}
        selected={selected}
        unselected={unselected}
        didFinish={handleClick} 
        setBackButtonDisabled={setBackButtonDisabled}
        setContinueButtonDisabled={setContinueButtonDisabled}
        setNextBackButtonDisabled={setNextBackButtonDisabled} 
        setNextContinueButtonDisabled={setNextContinueButtonDisabled}
        setBackground={setBackground}
        columnBackgroundColorActive={null}/>
      </>
    },
    {
      path: `/user/submit`,
      component: <>
      {/*currentUserData={currentUserData}*/}
        <Finish />
      </>
    },
  ];

  const pathArray = routes.map(item => {
    return item.path
  })

  useEffect(()=> {

    if (currentUserData) {

      const requestCareerWords = new Promise((resolve, reject) => {
      
        currentUserData.careerRef
        .get().then(doc => {
          
            if (doc.exists) {
              const data = doc.data()
              const promises = []
      
              data['selected'].map((item) => {
                return promises.push(item.get());
              })
              data['unselected'].map((item) => {
                return promises.push(item.get());
              })
      
              Promise.all(promises).then((snapshots) => {
      
                const words = []
                snapshots.forEach((snapshot, index) => {
                  let firebaseId = snapshot.id
                  words.push(Object.assign({}, snapshot.data(), {firebaseId}))
                })

                if (words.length != 12) {
                  reject('Data not retrieved correctly')
                }
      
                resolve(words);
      
              }).catch((err) => {
                reject(err);
              });
            } else {
              console.log("Doc doesn't exists")
            }
    
        }).catch(err => {
          reject(err)
        });
        
      });
      
      requestCareerWords.then((words) => {
        console.log(words)
        setSelected(words.slice(0,4))
        setUnselected(words.slice(4))
      })
      .catch((err) => {
        // TODO: error
        console.log(err)
      })

    }
  }, [currentUserData])
  

  useEffect(() => {

    let userSubscriber;
    const subdomainRef = props.firebase.firestore()
    .collection('clients').doc('revlocal')//.doc(`${props.client.subdomain}`)

    userSubscriber = subdomainRef
      .collection('candidates').doc(`${props.currentUser.uid}`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          setCurrentUserData({
            role: 'user',
            ...doc.data()
          })
        } else {
          props.history.push('/pagenotfound')
        }
      })

    return () => {
      if (userSubscriber) {
        userSubscriber()
      }
      
    }
  }, [props.firebase, props.currentUser]) // Dont add props.location even though eslint says to

  const handleBeforeunload = (e) => {
    e.preventDefault()
    setStateStorage({
      strengths: strengthData,
      miscellaneous: miscData,
      opportunity: oppData,
      start: startData,
      topFour: topFour,
      startFour: startFourData
    })
  }
  
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeunload)
    return () => window.removeEventListener('beforeunload', handleBeforeunload)
  }, [strengthData, miscData, oppData, startData, topFour, startFourData ])



  if (error) {
    return <Redirect to={'/pagenotfound'} />
  }

  if (!currentUserData || !selected || !unselected) {
    return <Loader style={{height: '100vh'}}/>
  }

  return <>
    <header className="App-header">
      <Progress custom="true" percent={Math.round(((pathArray.indexOf(props.location.pathname)))/(Math.max(routes.length-1, 1))*10000)/100} progress/>
    </header>
    <div className="App-main" style={{background: `url(${background})`, }}>
      <Container>

        <TransitionGroup data-transition-group style={{display:"flex", flexDirection: "row"}}>
          <CSSTransition
            key={props.location.pathname.substring(1).split('/')[1]}
            timeout={transitionDelay}
            classNames="Animate-Slide"
            unmountOnExit
            onExit={()=>{
              setBackButtonDisabled(true);
              setContinueButtonDisabled(true);
              setNextBackButtonDisabled(false);
              setNextContinueButtonDisabled(false);
            }}
            onEntered={()=> {
              setBackButtonDisabled(nextBackButtonDisabled);
              setContinueButtonDisabled(nextContinueButtonDisabled);
            }}
          >
            <Switch location={props.location}>
              {
                routes.map((item, i) => {
                  return (
                    <Route path={item.path} key={i} render={ (props)=> (
                      <div className="Animate-Slide">
                        {item.component}
                      </div>
                    )
                    }/>
                  )
                })
              }
              
              <Route>
                <Redirect to="/pagenotfound" />
              </Route>
              
            </Switch>
          </CSSTransition>
        </TransitionGroup>

        <div>
          {
            pathArray[0]!==match.url && pathArray[pathArray.length - 1]!==match.url && <Button disabled={backButtonDisabled} onClick={() => { handleClick(-1) }}>Go Back</Button>
          }
          {
            pathArray[pathArray.length - 1]!==match.url && <Button custom="true" disabled={continueButtonDisabled} onClick={() => { handleClick(1) }}>Continue</Button>
          }
          <br/>
          <br/>
        </div>
          
      </Container>
    </div>
    <div className="App-footer">
    </div>
  </>

}

export default withFirestore(withAuth(withRouter(User)));

function useSessionStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.sessionStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}