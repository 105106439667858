import userEvent from '@testing-library/user-event';
import React, { useEffect, useState } from 'react';
import { Link, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Form, Input, Grid, Message } from 'semantic-ui-react';
import { Button } from '../../components/Semantic';
import { withFirestore } from '../../config/firebase';
import CandidateProfile from '../CandidateProfile';
import Loader from '../FullScreenLoader'
import './css.scss';

const Finish = ({...props}) => {

    const [redirect, setRedirect] = useState(false)

   useEffect(() => {
       setTimeout(()=>{
        setRedirect(true)
       }, 3000)
   }, [])

   if (redirect) {
       
       window.location.href = props.client ? props.client.website : "https://www.landid.com"
       props.firebase.signOut()
   }

    return (
        <div>
            <h2 style={{color: "white"}}>Thank you</h2>
            <br /><br /><br /><br /><br />
            <h4 style={{color: "white"}}>You will be redirected momentarily</h4>
        </div>
    )
}

export default withFirestore((Finish));
