import React, { useEffect, useState } from "react"
import Skeleton from 'react-loading-skeleton'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import Word from '../../components/Word'
import { withFirestore, withAuth } from '../../config/firebase'
import Loader from '../../views/FullScreenLoader'

import './index.scss'

const DragContainer = ({setContinueButtonDisabled, setNextContinueButtonDisabled, setNextBackButtonDisabled, ...props}) => {

  const shuffle = (arr) => {
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr
  }

  const onDragEnd = (result, columns, setColumns) => {
  
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[parseInt(source.droppableId)];
      const destColumn = columns[parseInt(destination.droppableId)];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
  
      if (destColumn.header !== "Start" && destColumn.items.length >= 4) {
        // TODO: Tell user only 4 per column
        return;
      }
      
      destItems.splice(destination.index, 0, removed);
  
      setColumns(prevState => {
        let prevStateRef = [...prevState];
  
        prevStateRef[parseInt(source.droppableId)] = {
          ...sourceColumn,
          items: sourceItems
        };
  
        prevStateRef[parseInt(destination.droppableId)] = {
          ...destColumn,
          items: destItems
        };
  
        return [
          ...prevStateRef
        ]
      });

    } else {

      const column = columns[parseInt(source.droppableId)];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
  
      setColumns(prevState => {
        let prevStateRef = [...prevState];
  
        prevStateRef[parseInt(source.droppableId)] = {
          ...column,
          items: copiedItems
        };

        return [
          ...prevStateRef
        ]
      });
  
    }

  };

  const initColumns = [
    {
      header: "Top Strengths",
      items: props.strengthData ? props.strengthData.items : []
    },
    {
      header: "Wild Card",
      items: props.miscData ? props.miscData.items : []
    },
    {
      header: "Top Opportunities",
      items: props.oppData ? props.oppData.items : []
    },
    {
      header: "Start",
      items: props.startData ? props.startData.items : []
    }
  ];

  const [columns, setColumns] = useState(initColumns);

  useEffect(() => {

    setContinueButtonDisabled(true)

    setNextContinueButtonDisabled(true)
    setNextBackButtonDisabled(false)

    setColumns(prevState => {

      const startIndex = prevState.findIndex((element) => element.header === 'Start');
      const startCol = prevState[startIndex];

      console.log(startIndex)
      
      if (startCol.items.length +
        prevState[prevState.findIndex((element) => element.header === 'Top Strengths')].items.length +
        prevState[prevState.findIndex((element) => element.header === 'Wild Card')].items.length +
        prevState[prevState.findIndex((element) => element.header === 'Top Opportunities')].items.length !== 12
         ) {

          startCol['items'] = shuffle([...props.selected, ...props.unselected]);

          return [
            ...prevState.slice(0,startIndex).map((item, index) => {
              return {
                header: item.header,
                items: []
              }
            }),
            startCol,
            ...prevState.slice(startIndex+1).map((item, index) => {
              return {
                header: item.header,
                items: []
              }
            }),
          ]
        }
      

      return [...prevState]

    }) 
    
    return () => {
      
    };

  }, []);

  useEffect(() => {

    const startColumn = columns.filter((item) => {return item.header=="Start"})[0]

    if (startColumn.items) {
      setContinueButtonDisabled(!(startColumn.items.length==0))
    }
    
    props.setStartData(startColumn);
    props.setStrengthData(columns.filter((item) => {return item.header=="Top Strengths"})[0]);
    props.setMiscData(columns.filter((item) => {return item.header=="Wild Card"})[0]);
    props.setOppData(columns.filter((item) => {return item.header=="Top Opportunities"})[0]);
    
  }, [columns])

  if (props.currentUserData===null) {
    return <Loader/>
  }
  
  return (
    /* Div container of columns */
    <>
      <DragDropContext
        onDragEnd={result => onDragEnd(result, columns, setColumns, props)}
      >
        <div className="DragDropContext">
          {
            /* Each column mapped to a div */
          
            columns.map((column, columnId) => {
              /* 
                The columns object is flattened to a the key (0, 1, 2...) 
                as columnId and the value object {header: *, items: *} as column
                */
              return (
                <div 
                  className={"Column"}
                  data-column-name={column.header}
                  key={columnId}
                >
                  <h4 style={{margin: "4px"}}>{column.header}</h4>
                  <div className="DroppableContainer">
                    {
                      /* Loader if data is null else data columns */
                      !column.items ? 
                      <>
                          <div className={"Droppable Skeleton"}>
                            <Skeleton/>
                          </div>
                      </>
                      :
                      /* Droppable Columns defined - droppableId must be an integer passed as a string*/
                      <Droppable 
                        droppableId={String(columnId)} 
                        key={columnId + "-" +  columns.header} 
                        direction={column.header === "Start" ? "horizontal" : "vertical"}
                      >
                        {(provided, snapshot) => {
                          return (
                            /* 
                              This following element must be a div since the ref.innerRef 
                              returns a ref to the DOM node. If it were a React.Component
                              then it would return an instance of the component which is not
                              how react-dnd functions.
                            */
                            <div {...provided.droppableProps} ref={provided.innerRef}
                              data-direction={column.header === "Start" ? "horizontal" : "vertical"}
                              style={{
                                background: snapshot.isDraggingOver ? (props.columnBackgroundColorActive ? props.columnBackgroundColorActive : "lightblue") : (props.columnBackgroundColor ? props.columnBackgroundColor : "#eee"),
                              }}
                              className={"Droppable"}
                            >
                              {/* Mapping items of each column to draggable items */}
                              {column.items.map((item, index) => {
                                /* Each draggable is unique if it has its unique id combined with its columnid */
                                return (
                                  <Draggable
                                    key={item.firebaseId}
                                    draggableId={item.firebaseId}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        /* 
                                          This following element must be a div since the ref.innerRef 
                                          returns a ref to the DOM node. If it were a React.Component
                                          then it would return an instance of the component which is not
                                          how react-dnd functions.
                                        */
                                        
                                        <div className="Draggable"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            userSelect: "none",
                                            outline: "none",
                                            margin: "4px",
                                            display: "flex",
                                            minHeight: "60px",
                                            backgroundColor: "none",
                                            ...provided.draggableProps.style
                                          }}
                                        >
                                          <Word 
                                            wordData={item} 
                                            backgroundColor={snapshot.isDragging ? (props.draggableBackgroundColorActive ? props.draggableBackgroundColorActive : "gray") : (props.draggableBackgroundColor ? props.draggableBackgroundColor : "lightgray")}
                                            textColor={props.draggableTextColor}
                                            style={{
                                              overflow: "visible",
                                              boxShadow: "4px 4px 22px 0px rgba(0,0,0,0.05)",
                                            }}
                                            
                                          />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {/* Allows for no items to be in column */}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    }
                  </div>
                </div>
              );
            })
          }
        </div>
      </DragDropContext>
    </>
  );
}

export default withFirestore(withAuth(DragContainer));


