import React, { useState, useEffect } from 'react'
import { Table, Button, Segment, Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { withFirestore } from '../../config/firebase'
import WordCreation from '../WordCreation'
import Word2 from '../../components/WordQuestions'

const ComparisonTable = ({options, ...props}) => {

    useEffect(() => {
      console.log(props.oppData.items)
    }, [])

    return (
    <Grid columns={3} divided>
      <Grid.Row>
        <Grid.Column>
            <Segment inverted><b>Strengths</b></Segment>
        </Grid.Column>
        <Grid.Column>
            <Segment inverted><b>Miscellaneous</b></Segment>
        </Grid.Column>
        <Grid.Column>
            <Segment inverted><b>Opportunity</b></Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {
            props.strengthData.items && props.strengthData.items.map(({word, questionsArray}, index) => {
              return (
                <Segment raised key={index}>
                  <Word2 questionsArray={questionsArray}
                    wordData={word}>
                  </Word2>
                </Segment>
              ) 
            })
          }
        </Grid.Column>
        <Grid.Column>
          {
            props.miscData.items && props.miscData.items.map(({word, questionsArray}, index) => {
              return (
                <Segment raised key={index}>
                  <Word2 questionsArray={questionsArray}
                    wordData={word}>
                  </Word2>
                </Segment>
              ) 
            })
          }
        </Grid.Column>
        <Grid.Column>
          {
            props.oppData.items && props.oppData.items.map(({word, questionsArray}, index) => {
              return (
                <Segment raised key={index}>
                  <Word2 questionsArray={questionsArray}
                    wordData={word}>
                  </Word2>
                </Segment>
              ) 
            })
          }
        </Grid.Column>
      </Grid.Row>
    </Grid>
    )
}


export default withFirestore(ComparisonTable)