import React, { useState, useEffect } from 'react'
import { Table, Button, Segment, Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { withFirestore } from '../../config/firebase'
import WordCreation from '../WordCreation'

const ComparisonTable = ({options, ...props}) => {

    useEffect(() => {
      //companyPicks
    }, [])
 

    return (
    <Grid columns={3} divided>
      <Grid.Row> 
        <Grid.Column>
            <Segment inverted><b>Top 4</b></Segment>
        </Grid.Column>
        <Grid.Column>
            <Segment inverted><b>Your Top Strengths</b></Segment>
        </Grid.Column>
       
        <Grid.Column>
            <Segment inverted><b>Company Picks</b></Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {
            props.topFour && props.topFour.items.map(( {id, word}, index) => {
              return (
                <Segment key={index}>{word}</Segment>
              ) 
          })
          }
        </Grid.Column>
        <Grid.Column>
          {
            props.strengthData && props.strengthData.items.map(( {id, word}, index ) => {
              return (
                <Segment key={index}>{word}</Segment>
              ) 
          })
          }
        </Grid.Column>
        
        <Grid.Column>
          {
            props.selected && props.selected.map(( {id, word}, index ) => {
              return (
                <Segment key={index}>{word}</Segment>
              ) 
          })
          }
        </Grid.Column>
      </Grid.Row>
    </Grid>
    )
}


export default withFirestore(ComparisonTable)