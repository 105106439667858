import React from "react";
import { Popup } from "semantic-ui-react";
import './css.css';

const Word = ({ wordData, backgroundColor, textColor, style, ...rest }) => {
  return (
    <Popup
      className="Word-Item"
      trigger={
        <div 
          className="ui button" 
          style={{
            backgroundColor: backgroundColor ? backgroundColor : "#ccc",
            color: textColor ? textColor : "#555",
            minWidth: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...style
          }}
          {...rest}
        >
          {wordData.word}
        </div>
      }
      style={{
        border: 0
      }}
    >
      <Popup.Header>Definition</Popup.Header>
      <Popup.Content>{wordData.definition}</Popup.Content>
    </Popup>
  );
}

export default Word;