import React, { useState, useEffect } from 'react'
import { Table, Segment } from 'semantic-ui-react'
import { Button } from '../../components/Semantic'
import { Link, useHistory } from 'react-router-dom'
import { withFirestore } from '../../config/firebase'
import WordCreation from '../WordCreation'

const WordTable = ({options, ...props}) => {
    const [wordList, setWordList] = useState([])
    
    const history = useHistory()

    useEffect(() => {
      const subscriber = props.firebase.firestore()
      .collection('clients').doc('revlocal').collection('words')
      .onSnapshot((col)=> {
        const list = [];
        col.forEach(doc => {
          const element = doc.data();
          list.push({
            id: doc.id,
            word: element.word,
            definition: element.definition,
          })
        })
        list.sort((a, b) => a.word.localeCompare(b.word))
        setWordList(list)
      })

        //getData()

      return () => {
        if (subscriber) {
          subscriber()
        }

      }
    }, [])

    /*const removeData = (id) => {
      props.firebase.deleteWord(id)
      
    };*/

  const editData = (id) => {
    props.firebase.firestore().collection('clients').doc('revlocal').collection('words').doc(id).delete()
    .then(() => {
      console.log("Document successfully deleted!");
    }).catch((err) => {
      console.error("Error removing document: ", err);
    });
  }
    
  const removeData = (id) => {
    
    props.firebase.firestore().collection('clients').doc('revlocal').collection('words').doc(id).delete()
    .then(() => {
      console.log("Document successfully deleted!");
    }).catch((err) => {
      console.error("Error removing document: ", err);
    });
   // props.firebase.deleteWord(id)
  };

    return (
        <> 
        <Segment placeholder>
        <h3>Strength Bank</h3>
            <Table inverted celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Word</Table.HeaderCell>
                  <Table.HeaderCell>Definition</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  wordList && wordList.map(({ id, word, definition }) => {
                    return (
                      <Table.Row key={id}>
                        <Table.Cell>{word}</Table.Cell>
                        <Table.Cell>{definition}</Table.Cell>
                        <Table.Cell >
                          <Button negative custom="true" disabled={false} onClick={() => removeData(id)}>Delete</Button>
                        </Table.Cell>
                      </Table.Row>
                    ) 
                })
                }
              </Table.Body>
            </Table>
            <Button style={{color:'white', backgroundColor:'#CF1F2E'}} custom="true" onClick={()=>{history.push('/admin/new-word')}}>Add</Button>
           </Segment>

        </>
    )
}


export default withFirestore(WordTable)