import firebase from '@firebase/app';

import '@firebase/auth';
import '@firebase/storage';
import '@firebase/database';
import '@firebase/firestore';
import '@firebase/functions';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const Firebase = {
  // Custom services go here
  get firestore() {
    return firebase.firestore;
  },
  get functions() {
    return firebase.functions();
  },
  fetchImage: (path) => {
		return firebase.storage().ref().child(path).getDownloadURL();
	},
  loginWithEmail: (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  },
  signupWithEmail: (email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  },
  signOut: () => {
    return firebase.auth().signOut();
  },
  checkUserAuth: (callback) => {
    return firebase.auth().onAuthStateChanged(callback);
  },
  signInAnonymously: () => {
    return firebase.auth().signInAnonymously();
  },
  createNewCandidate: (subdomain, user) => {
    var docRef = firebase.firestore().collection('clients').doc(`${subdomain}`).collection('candidates').doc(`${user.id}`);
    return docRef.set(user, {merge: true});
  },
  listenToClient: (clientName, resolve, reject) => {
    return firebase.firestore().collection("clients").doc(clientName).onSnapshot(resolve, reject);
  },
  getWordList:  () => {
    return firebase.firestore().collection('clients').doc('revlocal').collection('words');
  },
  sendSignInLink: (email, actionCodeSettings) => {
    return firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
  },
  signInWithLink: (email, url=window.location.href) => {
    return firebase.auth().signInWithEmailLink(email, url)
  },
  isSignInLink: (url=window.location.href) => {
    return firebase.auth().isSignInWithEmailLink(url)
  },
  addStrengths: (strengths, misc, opp, id, cb) => {
    return firebase.firestore().collection('comparison').add({
      strengths: strengths,
      miscellaneous: misc,
      opportunity: opp,
      candidateId: id
    })
    .then((docRef) => {
      cb(docRef.id);
    })
    .catch((err) => {
      console.log(err);
    })
  },
  addTopFour: (topFour, id) => {
    return firebase.firestore().collection('comparison').doc(id).update({
      topFour: topFour
    })
  },
  returnStrengths: (id, cb) => {
    return firebase.firestore().collection('comparison').where('candidateId', '==', id).get()
    .then(docSnapShot => {
      docSnapShot.forEach(element => {
          console.log(element.data())
          cb(element.data())
      });
    }).catch((err) => {
      console.log(err, 'word-list-get-fail')
      cb(false)
    });
  },
  returnCompanyPicks: (careerRef) => { 
    return careerRef.get()
  },
  deleteWord: (id) => {
    const FieldValue = require('firebase-admin').firestore.FieldValue;
    firebase.firestore().collection('clients').doc('revlocal').collection('careers')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let word = '/clients/revlocal/words/' + id
          let index = doc.data().selected.indexOf(word)
          if(index >= 0) {
            doc.update({
              selected: FieldValue.arrayRemove(index)
            })
          }
          else {
            let index = doc.data().unselected.indexOf(word)
            if(index >= 0) {
              doc.update({
                unselected: FieldValue.arrayRemove(index)
              })
            }
          }
        })
      })
      .catch((err) => {
        console.log('Error deleting references' + err);
      })
    firebase.firestore().collection('clients').doc('revlocal').collection('words').doc(id).delete()
    .then(() => {
      console.log("Document successfully deleted!");
    }).catch((err) => {
      console.error("Error removing document: ", err);
    });
  }
}

export default Firebase;
