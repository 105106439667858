import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from 'react-router-dom';
import { Form, Message } from 'semantic-ui-react';
import Container from '../../components/Container';
import { Button } from '../../components/Semantic';
import { withAuth, withFirestore } from '../../config/firebase';
import Loader from '../FullScreenLoader';
import './index.scss';


 
const Login = ({...props}) => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)

  const [loading, setLoading] = useState(false)

  const [isLoginUrl, setIsLoginUrl] = useState(null)

  const [client, setClient] = useState(null)

  const [options, setOptions] = useState(null)

  const history = useHistory();


  const _handleLogin = () => {

    setError(false)

    if (!client && !isLoginUrl) {
      setError({header: 'Client', content: 'Please choose your business'});
      return ;
    }

    if (email.toLowerCase().indexOf('.') < 0 || email.toLowerCase().indexOf('@') < 0) {
      setError({header: 'Email', content: 'The email was formatted incorrectly'});
      setEmail('')
      setPassword('')
      return ;
    }

    if (isLoginUrl) {
      // Url login means the user is a user
      console.log('Login Url')
      const { pathname, search } = props.location.state.from;
      const restoredUrl = window.location.origin + pathname + search;

      setLoading(true);
      props.firebase.signInWithLink(email, restoredUrl)
        .catch((error) => {
          setLoading(false)
          if (error.code === "auth/invalid-email") {
            setError({header: 'Email', content: "The email provided does not match the sign-in email address"})
          } else if (error.code === "auth/invalid-action-code") {
            setError({header: 'Link Error', content: 'This link may have been used already or it expired'})
          }
        });

    } else if (password.length <= 5 ) {
      setError({header: 'Password', content: 'The password is too short'});
    } else if (password.toLowerCase() === password) {
      setError({header: 'Password', content: 'The password needs at least one uppercase character'});
    } else {
      // Handle login means the user is an admin

      console.log('Admin login')
      setLoading(true)

      props.firebase.loginWithEmail(email, password)
      .catch((err) => {
        setTimeout(() => {
          setLoading(false)
          if (err.code==="auth/wrong-password") {
            setError({header: 'Error', content: `The credentials were incorrect, or the user doesn't exist. 
            This login is for Administrators only. To create an account contact landid.`})
          } else {
            setError({header: 'Error', content: "Could not log in currently"})
          }
        }, 1000)
      })

    }

  }

  useEffect(() => {

    if (props.currentUser) {
      
      // Email auth
      if (isLoginUrl && props.location.state.from) {

        const { search } = props.location.state.from;

        const subdomainRef = props.firebase.firestore()
        .collection('clients').doc((new URLSearchParams(search)).get('client'))

        subdomainRef.collection('candidates').doc(`${props.currentUser.uid}`)
        .set({
          id: props.currentUser.uid,
          email: props.currentUser.email,
          created: props.firebase.firestore.FieldValue.serverTimestamp(),
          careerRef: subdomainRef
            .collection('careers')
            .doc((new URLSearchParams(search)).get('career'))
        }).then(() => {
          history.goBack();
        }).catch(err => {
        })
        
      } else {

        if (client == null || client == undefined || !client) {
          props.firebase.signOut();
        }

          props.firebase.firestore()
          .collection('clients').doc(client).collection('admins').doc(props.currentUser.uid).get()
          .then((doc) => {

            if (doc.exists) {
              console.log(`clients/${client}/admins/${props.currentUser.uid}`);
              props.history.push({
                pathname: '/admin/home'
              })
            } else {
              setClient(null);
              props.firebase.signOut()
              .then(() => {
                setError({header: 'Error', content: `The credentials were incorrect, or the user doesn't exist. 
                This login is for Administrators only. To create an account contact your business.`});
              })
            }
            
            setLoading(false);
  
          })
          
        
      }
      
    }
    
  }, [props.currentUser])

  useEffect(() => {

    if (!isLoginUrl) {

      props.firebase.firestore()
        .collection('clients').get()
        .then((col)=> {
          const list = [];
          col.forEach(doc => {
            const element = doc.data();
            const isClient = (new URLSearchParams(window.location.search)).get('client') === element.id_name;
            const data = {
              key: element.id_name,
              text: element.comp_name,
              value: element.id_name,
              selected: isClient
            };
            if (isClient) {
              setClient(data.key);
            }
            if (data.value) {
              list.push(data)
            }
          })
          list.sort((a, b) => a.text.localeCompare(b.text))
          setOptions(list)
        })

      

    }

  }, [isLoginUrl])
  
  useEffect(() => {

    setIsLoginUrl(
      props.location.state && 
      props.location.state.from && 
      props.firebase.isSignInLink(
        window.location.origin + props.location.state.from.pathname + props.location.state.from.search
      ))

    return () => {
      console.log("... Dismounting")
    };
  }, [props.location, props.firebase]);


  if (loading) {
    return <Loader style={{height: "100vh"}}/>
  }
  

  return <>
    <header className="App-header">
    </header>
    <div className="App-main">
      <Container style={{
        width: "100%", 
        height: "100vh",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        backgroundColor: 'var(--brand-subcolor-one, #fff)'

      }}>
          <div style={{textAlign: 'left', minWidth: '400px', maxWidth: '600px'}}>
            {
              !isLoginUrl && <h2 className="Brand-Color" style={{textShadow: "4px 4px 8px rgba(0,0,0,0.05)"}}>Admin Login</h2>
            }

            {
              !isLoginUrl && options &&

              <Form>
                <Form.Group>
                    <Form.Select placeholder="Client" options={options} defaultValue={
                      options.filter(item => item.selected).length > 0 ? options.filter(item => item.selected)[0].key : null
                    } onChange={(e, {value})=> {setClient(value)}} />
                </Form.Group>
              </Form>
            }
            
            <div className="shadow" style={{borderRadius: 8, padding: 30, marginTop: 40}}>
            
            <Form error={error ? true : false} onSubmit={_handleLogin}>
              <Form.Input
                fluid
                label='Email'
                placeholder='Email'
                name='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              
              {
                !isLoginUrl && <Form.Input
                fluid
                label='Password'
                placeholder='Password'
                name='password'
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              }
              <Message
                error
                header={error.header}
                content={error.content}
              />
              <Button custom="true" type='submit' content="Login" disabled={loading}/>
            </Form>
            </div>
          </div>
      </Container>
    </div>
    <div className="App-footer">
    </div>
  </>

}

export default withFirestore(withAuth(withRouter(Login)));

