import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { withFirestore } from '../../config/firebase';
import Loader from '../FullScreenLoader';
import './css.css';

const CandidateProfile = ({...props}) => {

    const [candidate, setCandidate] = useState(null);

    useEffect(() => {
        props.firebase.firestore().doc(`clients/revlocal/candidates/${props.match.params.candidateId}`)
        .get().then((doc) => {
            if (doc.exists) {
                setCandidate({
                    id: props.match.params.candidateId,
                    ...doc.data()
                })
            } else {
                setCandidate(false)
            }
            
        }).catch((err) => {
            setCandidate(false)
        })

    }, [props.firebase, props.match.params.candidateId])

    if (candidate === false) {
        return <Redirect to={`/pagenotfound`}/>
    }

    if (candidate === null) {
        return <Loader />
    }

    return (
        <div>
            <h3>{candidate.name || 'John Smith'}</h3>
            <p>{candidate.id || 'Id'}</p>
            <p>{candidate.email || 'Email'}</p>
        </div>
    )
}

export default (withFirestore(withRouter(CandidateProfile)));
