import React, { useState, useEffect } from 'react'
import { Table, Segment } from 'semantic-ui-react'
import { Button } from '../../components/Semantic'
import { Link, useHistory } from 'react-router-dom'
import { withFirestore } from '../../config/firebase'
import JobCreation from '../JobCreation'

const JobTable = ({options, ...props}) => {
    const [jobList, setJobList] = useState([])

    const history = useHistory()

    useEffect(() => {
      const subscriber = props.firebase.firestore()
      .collection('clients').doc('revlocal')
      .collection('careers')
      .onSnapshot((col)=> {
        const list = [];
        col.forEach(doc => {
          const element = doc.data()
          list.push({
            id: doc.id,
            title: element.title,
            description: element.description,
          })
        })
        setJobList(list)
      })

        //getData()

      return () => {
        if (subscriber) {
          subscriber()
        }

      }
    }, [])

  const removeData = (id) => {
    props.firebase.firestore().collection('clients').doc('revlocal')
    .collection('careers').doc(id).delete()
      .then(() => {
        console.log("Document successfully deleted!");
      }).catch((err) => {
        console.error("Error removing document: ", err);
    });
    
  };

    return (
        <> 
        <Segment placeholder>
          <h3>Jobs</h3>
            <Table inverted celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Job Title</Table.HeaderCell>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  jobList && jobList.map(({ id, title, description }) => {
                    return (
                        <Table.Row key={id}>
                          <Table.Cell>{title}</Table.Cell>
                          <Table.Cell>{description}</Table.Cell>
                          <Table.Cell>
                            <Button custom="true" onClick={() => history.push({pathname: '/admin/edit-job', search: `?jobid=${id}`})}>Open</Button>
                          </Table.Cell>
                          <Table.Cell >
                            <Button negative custom="true" onClick={() => removeData(id)}>Delete</Button>
                          </Table.Cell>
                        </Table.Row>
                    ) 
                })
                }
              </Table.Body>
            </Table>
            <Button style={{color:'white', backgroundColor:'#CF1F2E'}} custom="true" onClick={()=>{history.push('/admin/new-job')}}>Add</Button>
            </Segment>

        </>
    )
}


export default withFirestore(((JobTable)))

