import React, { useEffect, useState } from "react";
import { Button, Segment, Label, Image, Grid,  } from "semantic-ui-react";
import './css.css';
//import { CSSTransition, TransitionGroup  } from 'react-transition-group';
//import { Link } from "react-router-dom"
import './index.css';
import Container from "../Container";

const CardContainer = ({didFinish, setNextBackButtonDisabled, setNextContinueButtonDisabled, setContinueButtonDisabled, ...props}) => {
  const [wordCardData, setWordCardData] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  
  const _onclick = (value) => {
    setCurrentIndex(currentIndex+1)
    setContinueButtonDisabled((currentIndex + 1 !== wordCardData.length))
    
    if (currentIndex + 1 === wordCardData.length) {
      didFinish(1)
    }
  }

  useEffect(() => {
    setContinueButtonDisabled(true)
    
    setNextBackButtonDisabled(false)
    setNextContinueButtonDisabled(true)
    
    const data = [
      { word1: "Dog", word2: "Cat" , img1: "https://firebasestorage.googleapis.com/v0/b/landid-36f39.appspot.com/o/Image%2Flabrador.jpg?alt=media&token=5064aa38-7be3-4e3e-bf39-1193cc24fb67", img2: "https://firebasestorage.googleapis.com/v0/b/landid-36f39.appspot.com/o/Image%2Fcat.jpg?alt=media&token=fafc7f06-df21-44c6-9765-5f6fbf085f74"},
      { word1: "Comedy", word2: "Action" , img1: "https://firebasestorage.googleapis.com/v0/b/landid-36f39.appspot.com/o/Image%2Fcomedy.jpg?alt=media&token=7086ace7-8bc9-49e3-913a-45d301f4fd6e", img2: "https://firebasestorage.googleapis.com/v0/b/landid-36f39.appspot.com/o/Image%2Faction.jpg?alt=media&token=550c4a89-d406-4eee-8914-b60ff12b3ca5"},
      { word1: "Peanut Butter", word2: "Jelly" , img1: "https://firebasestorage.googleapis.com/v0/b/landid-36f39.appspot.com/o/Image%2Fpeanut.jpg?alt=media&token=7e1ec3e2-eecb-48b3-b130-80348798cbb4", img2: "https://firebasestorage.googleapis.com/v0/b/landid-36f39.appspot.com/o/Image%2FJam.jpg?alt=media&token=3f8c0c45-ffe0-4f78-b9ce-9c11e572a899"},
      { word1: "Fly", word2: "Drive" , img1: "https://firebasestorage.googleapis.com/v0/b/landid-36f39.appspot.com/o/Image%2Ffly.jpg?alt=media&token=b580589c-3406-455d-a965-bec60a0ef8c6", img2: "https://firebasestorage.googleapis.com/v0/b/landid-36f39.appspot.com/o/Image%2Fcar.jpg?alt=media&token=633618a6-b0b8-4d04-a585-307940a34ba5"}
    ];

    setWordCardData(data);
    
  }, [setContinueButtonDisabled, setNextBackButtonDisabled, setNextContinueButtonDisabled])


  return <>
    <Container>
      {
        wordCardData.map((item, i) => (
          <div key={i}>
            {
            currentIndex === i ? 
            <div className="center" style={{marginTop: 50, marginBottom: 5}}>
              <div>
                <Grid columns={2}>
                  <Button.Group>
                      <Grid.Column>
                        <Segment>
                          <Button 
                            onClick = {() => {_onclick(item.word1)}}
                            inverted color = "red" 
                            size='massive'
                            fluid>
                              <Label attached='bottom left' size='massive'>{item.word1}</Label>
                              <Image src={item.img1} size='large' rounded/>
                            {/* replaced with labels
                            item.word1*/}
                          </Button>
                        </Segment>
                      </Grid.Column>
                    <Button.Or />
                      <Grid.Column>
                        <Segment>
                          <Button
                            onClick = {() => {_onclick(item.word2)}}
                            inverted color = "red" 
                            size='massive'
                            fluid
                            >
                              <Label attached='bottom right' size='massive'>{item.word2}</Label>
                              <Image src={item.img2}  size='large' rounded/>
                            {/* Replaced this with labels
                            item.word2*/}
                          </Button>
                        </Segment>
                      </Grid.Column>
                  </Button.Group>
                </Grid>
              </div> 
            </div>
            :
            <></>
          }
          </div>
        ))
      }
      </Container>
  </>

}

export default CardContainer;
