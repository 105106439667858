import React, {  useState, useEffect } from 'react'
import { Form, List, Button, Grid, Message, Segment } from 'semantic-ui-react'
import { withFirestore, withClient } from '../../config/firebase'
import wordcreationimage from "../../assets/wordcreation.jpg"

class WordBank extends React.Component {

  constructor(props){
    super(props)
    this.initState = {
      word: '',
      definition: '',
      question1: '',
      //question2: '',
      //question3: ''
    } 
    this.state = {...this.initState, status: false}
  }

  componentDidMount() {
    this.props.setBackground(wordcreationimage)
  }



 handleChange = (e, { word, value }) => this.setState({ [word]: value })
 
 handleSubmit = () => {
   const { word, definition, question1 /*question2, question3 */} = this.state
   console.log(this.state)
   
   if (!(word!=='' && definition!=='' && question1!=='' /*&& question2!=='' && question3!==''*/)) {
     this.setState(prevState => ({
       ...prevState,
        status: {
          header: 'Error',
          content: 'The form is not formatted correctly'
        }
    }))
     return;
   }
   
   this.props.firebase.firestore().collection('clients').doc('revlocal').collection('words').add({
      word: word,
      definition: definition,
      questionsArray: [question1/*, question2, question3*/]
    }).then(() => {
      this.setState({
        ...this.initState,
        status: {
          header: 'Success',
           content: 'The Strength was added'
        }
      })
    }).catch(() => {
      this.setState({
        ...this.initState,
        status: {
          header: 'Error',
           content: 'The Strength could not be added currently'
        }
      })
    })
    
 }

render() {
  
  this.props.setBackground(wordcreationimage)

  const { word, definition, question1, question2, question3, status } = this.state

  return (
    <div style={{margin: "50px 0 0 300px", width: "100%",  justifyContent: "center", maxWidth:"50%"}}>
      <Grid centered columns='equal'>
      <Segment size='massive' compact>
      <h4>Create New Strength </h4>
        <Grid.Row stretched>
          <Grid.Column>
            <Form success={status.header==="Success"} error={status.header==="Error"} onSubmit={this.handleSubmit}>
              <Form.Group widths='equal'>
                <Form.Input
                  size = 'massive'
                  fluid label='Strength'
                  placeholder='Enter Strength'
                  word='word'
                  value={word}
                  onChange={this.handleChange}
                />
              </Form.Group>
                <Form.TextArea
                  label='Definiton' 
                  placeholder='Definition...'
                  word='definition'
                  value={definition}
                  onChange={this.handleChange}
                />
              <List>
                <List.Item >
                  <Form.TextArea
                      size = 'small'
                      label='Question 1' 
                      placeholder='Input Interview Question...'
                      word='question1'
                      value={question1}
                      onChange={this.handleChange}
                    />    
                 </List.Item>
                 {/* <List.Item >
                    <Form.TextArea
                      size = 'small'
                      label='Question 2' 
                      placeholder='Input Question...'
                      word='question2'
                      value={question2}
                      onChange={this.handleChange}
                    />    
                 </List.Item>
                  <List.Item >
                    <Form.TextArea
                      size = 'small'
                      label='Question 3' 
                      placeholder='Input Question...'
                      word='question3'
                      value={question3}
                      onChange={this.handleChange}
                    />    
                  </List.Item>*/}
                </List>
              <Message
                success
                header={status.header}
                content={status.content}
                />
              <Message
                error
                header={status.header}
                content={status.content}
              />
            <Form.Button content='Submit' />
          </Form>
        </Grid.Column>
      </Grid.Row>
      </Segment>
    </Grid>
  </div>
)}
}

export default withFirestore(WordBank)



/* <Dropdown
            placeholder='questionsArray'
            fluid
            multiple
            search
            selection
            word="questionsArray"
            value={questionsArray || []}
            onChange={this.handleChange}
            options={this.state.wordList ? this.state.wordList : [] }
        />*/
/*
 handleChange(e, { word, value })
 { this.setState({ [word]: value })}

handleSubmit = () => {
  console.log(this.state)
}

componenentDidMount() {
  this.props.firebase.getWordList().limit(10).get()
      .then(collectionSnapshot => {
        const list = [];
        collectionSnapshot.forEach(element => {
          console.log(element.data())
          
         list.push({
            key: element.data().word,
            text: element.data().word,
            value: element.data().word
        });
        console.log(list)
        this.setState({wordList: list})
        });
      }).catch((err) => {
        console.log(err, 'word-list-get-fail')
      });
    
}*/