import React from "react";
import './index.scss';


const Container = ({...props}) => {

    const {className, style} = props;

    const defaultStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        textAlign: "center",
        width: "100%",
        height: "auto",
        boxSizing: "border-box"
    }

    return <div className={`Container Brand-Font ${className ? " " + className : ''}`} style={{...defaultStyle, ...style}}>
        {props.children}
    </div>
}

export default Container;
