import React, { useEffect } from 'react'
import { Segment } from "semantic-ui-react"
import { withAuth, withClient } from '../../config/firebase'
import assocbackground from '../../assets/AssociationBackground.jpg'
import questionbackground from '../../assets/QuestionBackground.jpg'
import temporaryImage from '../../assets/fillerimg.jpg'
//import Container from '../Container'

const Instructions = ({...props}) => {

  const {title, subtitle, pchild, graphic} = props;

  useEffect(() => {
    if(props.go == "/user/association-instruction"){
      props.setBackground(assocbackground)
    }else if(props.go == "/user/question-instruction"){
      props.setBackground(questionbackground)
    }else{
      props.setBackground(temporaryImage)
    }

  }, [])

  return (
    
    <div style={{
      display: "flex", 
      padding: "5%", 
      justifyContent: "space-around", 
      height: "75vh", 
      overflow: "hidden"
      }}>
      <div style={{margin: "4%", flexGrow: 0.5, flexBasis: "50%", color: "white"}}>
        <h2><span style={{textTransform: "none", fontWeight: 600}}>{title}</span> </h2>
        <p style={{marginTop: 5, marginBottom: 5}}>{subtitle}</p>
        {pchild}
      </div>
      <div style={{margin: "4%", flexGrow: 0.5, flexBasis: "50%", position: "relative"}}>
        <img src={graphic} alt="loading..." style={{
          borderRadius: "25px",
          border: "2px solid white",
          padding: "20px",

          width: "100%", 
          maxWidth: "400px", 
          minWidth: "250px",
          boxShadow: "4px 4px 22px 0px rgba(0,0,0,0.05)",
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: "auto",
          }}/>
      </div>
    </div>
  );
}

export default withAuth(Instructions);