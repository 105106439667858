import React from 'react'
import { Loader, Container } from 'semantic-ui-react'

const LoaderExample = ({...props}) => {
    const { style } = props;

    return <Container className="centered" style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...style
      }}>
        <Loader active/>
    </Container>
}

export default LoaderExample

