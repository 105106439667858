import React, { createContext } from 'react';

// Creates a context
const FirebaseContext = createContext();

export const FirebaseProvider = FirebaseContext.Provider;

const FirebaseConsumer = FirebaseContext.Consumer;

export const withFirestore = Component => props => (
  <FirebaseConsumer>
    {context => <Component {...props} firebase={context} />}
  </FirebaseConsumer>
);
