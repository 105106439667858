//import React from 'react';
import styled from 'styled-components';
import { Button as But, Progress as Pro, List as Lis } from 'semantic-ui-react';

export const Button = styled(But)`
    &&& {
        background-color: ${props => props.custom==="true" ? "var(--brand-maincolor, #fff)" : ""};

        &:hover {
            background-color: ${props => props.custom==="true" ? "var(--brand-maincolor, #ddd)" : ""};
            filter: ${props => props.custom==="true" ? "brightness(90%)" : ""};
        }

        color: ${props => props.custom==="true" ? "var(--brand-maincolor, #000)" : ""};
    }
`

export const Progress = styled(Pro)`
    &&& {

        .bar {
            background-color: ${props => props.custom==="true" ? "var(--brand-maincolor, #aaa)" : ""};
        }
    }
`

export const List = styled(Lis)`
`