import React, { useEffect,  } from "react"
import { Link, Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
//import Loader from "./views/FullScreenLoader"
import './App.scss'
import { PrivateRoute, withAuth, withFirestore } from './config/firebase'
import styles from './styles/_delays.module.scss'
import Admin from './views/Admin'
import Login from './views/Login'
import PageNotFound from "./views/PageNotFound"
import User from './views/User'

 
const App = ({...props}) => {

  const transitionDelay = parseInt(styles.viewFadeInOutDelay);

  const changeFavicon = (src) => {
    var link = document.createElement('link'), oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-favicon';
    link.rel = 'shortcut icon';
    link.href = src;
    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
  }

  useEffect(() => {

    /* Setting title of page to company name */
    // document.title = props.client.comp_name

    /*props.firebase.fetchImage(props.domain + '/favicon.svg').then((url) => {
      changeFavicon(url);
    }).catch((err) => {
      console.log("Could not load favicon", err);
    });*/

    return () => {
      // props.firebase.signOut()
    };
  }, [props.firebase]);

  return (
    <div className="App Brand-Subfont-One">

      <TransitionGroup data-transition-group>
        <CSSTransition
          key={props.location.pathname.substring(1).split('/')[0]}
          timeout={transitionDelay}
          classNames="Animate-Main"
          unmountOnExit
        >
          <Switch location={props.location}>

            <PrivateRoute 
              exact path="/" 
              redirectTo="/login" 
              condition={Boolean(props.currentUser)}
              children={
                <div className="Animate-Main">
                  <h1>Hello main</h1>
                </div>
              }
            />
          
            <Route exact path="/pagenotfound">
              <div className="Animate-Main">
                <PageNotFound transitionDelay={transitionDelay}/>
              </div>
            </Route>

            <Route exact path="/login">
              <div className={`Animate-Main`}>
                <Login transitionDelay={transitionDelay}/>
              </div>
            </Route>

            <PrivateRoute 
              exact path="/user/*" 
              redirectTo="/login" 
              condition={Boolean(props.currentUser)}
              children={
                <div className="Animate-Main">
                  <User transitionDelay={transitionDelay}/>
                </div>
              }
            />

            <PrivateRoute 
              exact path="/admin/*" 
              redirectTo="/login" 
              condition={Boolean(props.currentUser)} 
              children={
                <div className="Animate-Main">
                  <Admin transitionDelay={transitionDelay}/>
                </div>
              }
            />

            <Route>
              <Redirect to="pagenotfound" />
            </Route>
            
          </Switch>
        </CSSTransition>
      </TransitionGroup>

    </div>
  );

}

export default withFirestore(withAuth(withRouter((App))));
